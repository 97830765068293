import { merge } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: 'text-content-sm',
  variants: merge(BaseTagTheme.variants, {
    colors: {
      default: `bg-linen-300 text-black hover:bg-linen-400 focus:bg-linen-400 active:bg-linen-400 active:shadow-[inset_0px_0px_2px] active:shadow-black/25`,
    },
    size: {
      small: `px-3 py-1`,
      medium: `px-3 py-1.75`,
      large: `px-6 py-2.75`,
      video: 'mb-1 text-category-sm md:text-category-lg',
    },
  }),
});

export default Tag;
